.notifications-container {
  padding-top: 3rem;

  h3 {
    margin-bottom: 3rem;
  }

  .flex-grow-1 {
    flex: 1;
  }
}
