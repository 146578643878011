/* Color palette */
$red: #d73f3f;
$red-light: #ffeded;
$orange: #faa71e;
$blue-dark: #2c3038;
$blue-light: #929db3;
$blue-grey: #68707f;
$washed-blue: rgba(146, 157, 179, 0.1);
$grey-light: #e1e0e0;
$tan: #f0efef;
$white: #fff;
$green1-geovelo: #EEF9F4;
$green2-geovelo: #D0F9E7;
$green3-geovelo: #8EEAC2;
$green4-geovelo: #2ac682;
$green5-geovelo: #03825c;
$green6-geovelo: #005a44;
$blue1-geovelo: #f3f6ff;
$blue2-geovelo: #d0f9e7;
$blue3-geovelo: #9cbdf4;
$blue4-geovelo: #528be8;
$blue5-geovelo: #326ac2;
$blue6-geovelo: #0a429a;
